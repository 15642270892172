import React from "react";
import Login from "../components/Login";

export default function LoginScreen() {
	return (
		<>
			<Login />
		</>
	);
}
